import { WheelLayout } from "./Interfaces";

interface Vec2 {
  x : number,
  y : number
}

const START_SPEED = 2 * Math.PI / 20;

export class Wheel {
  private maxTurns : number = 400;
  private currentTurn : number = 0;
  private center : Vec2 = {x: 0, y: 0};
  private entries : string[] = [];
  private centerText : string = '';
  private innerRadius : number = 0;
  private outerRadius : number = 0;
  private turnAngle : number = START_SPEED;

  constructor(layout : WheelLayout) {
    this.centerText = layout.centerText;
    this.entries = layout.entries;
  }

  setInnerRadius(r : number) {
    this.innerRadius = r;
  }

  setOuterRadius(r : number) {
    this.outerRadius = r;
  }

  setRandTurns(max : number, min : number) {
    this.maxTurns = Math.random() * (max - min) + min;
  }

  setCenterText(text : string) {
    this.centerText = text;
  }

  getCurrentTurn() : number {
    return this.currentTurn;
  }

  getMaxTurns() : number {
    return this.maxTurns;
  }

  setPosition(centerX : number, centerY : number) {
    this.center.x = centerX;
    this.center.y = centerY;
  }

  spinWheel(context : CanvasRenderingContext2D) {
    context.transform(1, 0, 0, 1, this.center.x, this.center.y);
    context.transform(Math.cos(this.turnAngle), -Math.sin(this.turnAngle), Math.sin(this.turnAngle), Math.cos(this.turnAngle), 0, 0);
    context.transform(1, 0, 0, 1, -this.center.x, -this.center.y);
    this.turnAngle = (-START_SPEED / this.maxTurns) * this.currentTurn + START_SPEED;

    this.currentTurn++;
  }

  resetWheel(context : CanvasRenderingContext2D) {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    this.turnAngle = START_SPEED
    this.currentTurn = 0;
    this.draw(context);
  }

  draw(context : CanvasRenderingContext2D) { 
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // var dpr = window.devicePixelRatio || 1;
    // var rect = context.canvas.getBoundingClientRect();
    // context.canvas.width = rect.width * dpr;
    // context.canvas.height = rect.height * dpr;
    
    // context.scale(dpr, dpr);

    // context.canvas.style.width = rect.width + "px";
    // context.canvas.style.height = rect.height + "px";

    // this.center = {x: +context.canvas.style.width / 2, y: +context.canvas.style.height / 2}

    this.resizeInnerCircle(context);
    this.drawOuterCircle(context);
    this.drawSegments(context);
    this.drawEntries(context);
    this.drawCenter(context);
    this.drawTicker(context);
  }

  private resizeInnerCircle(context : CanvasRenderingContext2D) {
    context.font = '48px serif';
    context.fillStyle = 'black';
    this.setInnerRadius(20 + context.measureText(this.centerText).width / 2);
  }

  private drawOuterCircle(context : CanvasRenderingContext2D) {
    context.beginPath();
    context.arc(this.center.x, this.center.y, this.outerRadius, 0, 2 * Math.PI, false);
    context.fillStyle = 'white';
    context.fill();
    context.stroke();
    
    context.beginPath();
    context.arc(this.center.x, this.center.y, this.innerRadius, 0, 2 * Math.PI, false);
    context.stroke();
  }

  private drawSegments(context : CanvasRenderingContext2D) {
    let angle = 2 * Math.PI / this.entries.length;
    let c = 0;
    for (let i = 0; i < this.entries.length; i++) {
      context.moveTo(this.center.x + this.innerRadius * Math.cos(c), this.center.y + this.innerRadius * Math.sin(c));
      context.lineTo(this.center.x + (this.outerRadius + 10) * Math.cos(c), this.center.y + (this.outerRadius + 10) * Math.sin(c));
      context.strokeStyle = 'rgb(0, 0, 0)';
      context.stroke();
      c += angle;
    }
  }

  private drawEntries(context : CanvasRenderingContext2D) {
    let angle = 2 * Math.PI / this.entries.length;
    let c = 0;

    for (let i = 0; i < this.entries.length; i++) {
      context.save();
      context.font = '48px serif';
      context.fillStyle = 'black';

      context.transform(1, 0, 0, 1, this.center.x, this.center.y);
      context.transform(Math.cos(c + 1/2 * angle), -Math.sin(c + 1/2 * angle), Math.sin(c + 1/2 * angle), Math.cos(c + 1/2 * angle), 0, 0);
      context.transform(1, 0, 0, 1, -this.center.x, -this.center.y);

      let metrics = context.measureText(this.entries[i]);
      let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

      context.fillText(this.entries[i], this.innerRadius + (this.outerRadius - this.innerRadius) / 2 - context.measureText(this.entries[i]).width / 2 + this.center.x + Math.cos(c + angle),
            actualHeight / 2 + this.center.y + (Math.sin(c + angle)));

      c += angle;
      context.restore();
    }
  }

  private drawTicker(context : CanvasRenderingContext2D) {
    context.save();
    context.resetTransform();
    context.beginPath();

    context.moveTo((this.outerRadius + this.center.x) + 10, this.center.y - 20);
    context.lineTo((this.outerRadius + this.center.x) - 10, (this.center.y));
    context.lineTo((this.outerRadius + this.center.x) + 10, (this.center.y) + 20);            
    
    context.fillStyle = 'red';
    context.fill();
    context.restore();
  }

  private drawCenter(context : CanvasRenderingContext2D) {
    context.save();
    context.resetTransform();
    context.font = '48px serif';
    context.fillStyle = 'black';
    context.fillText(this.centerText, this.center.x - context.measureText(this.centerText).width / 2, this.center.y + 10);
    context.restore();
  }
}
