import { collection, getDocs, addDoc} from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react';
import {db} from '../firebase-config';
import {WheelLayout, LayoutStateProp} from './util/Interfaces';
import Dropdown from 'react-bootstrap/Dropdown';
import { Alert, Button, Col, DropdownButton, FloatingLabel, Form, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import { useContext } from 'react';
import { UserContext } from '../user/UserContext';

interface AlertOps {
  variant: string,
  message: string
}

function LayoutControls(props : LayoutStateProp) {
  const layoutCollection = collection(db, "layouts");
  const [layouts, setLayouts] = useState<{layout: WheelLayout,id: string}[]>([]);

  const userState = useContext(UserContext);
  const currentUserState = userState?.userState;

  const titleRef = useRef<null | HTMLInputElement>(null);
  const [show, setShow] = useState(false);

  const [showPleaseLoginAlert, setShowPleaseLoginAlert] = useState(!!!currentUserState);
  const [validatedSave, setValidatedSave] = useState(false);

  const [currentAlert, setCurrentAlert] = useState<AlertOps>({variant: "", message: ""});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateLayouts = useRef(true);

  useEffect(() => { 
    if (updateLayouts.current) {
      const getLayouts = async () => {
        const data = await getDocs(layoutCollection);
        let localLayouts : {layout : WheelLayout, id : string}[] = [];
        data.docs.map((doc) => {
          localLayouts.push({layout: {
            centerText : doc.data().centerText,
            entries: doc.data().entries,
            title: doc.data().title
          }, id : doc.id})
        });

        setLayouts(localLayouts);
      }
      
      updateLayouts.current = false;
      getLayouts();
    }

  }, [layoutCollection]);

  const saveLayout = async () => {
    if (!titleRef.current) return;

    const currentLayout = {...props.state[0], title: titleRef.current.value};

    await addDoc(layoutCollection, currentLayout)
    .then(() => {
      setCurrentAlert({message: "Save was successful", variant: "success"});
    }).catch((error)=> {
      setCurrentAlert({message: "Save failed: " + error.message, variant: "danger"});
    }); 
    
    updateLayouts.current = true;
  }

  const loadLayout = async (id: string) => {
    for (let layout of layouts) {
      if (layout.id === id) {
        const newLayout : WheelLayout = {
          centerText: layout.layout.centerText,
          entries: layout.layout.entries,
          title: layout.layout.title
        }
        
        props.state[1](newLayout);
        break;
      }
    }
  }

  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (form.checkValidity() === true) {
      handleShow();
      event.preventDefault();
      event.stopPropagation();
    }

    //setValidatedSave(true);
  };

  return (
    <>
      {!!!currentUserState && showPleaseLoginAlert && (
        <Alert variant='danger' onClose={() => {setShowPleaseLoginAlert(false)}} dismissible>
          Login to save or load wheel layouts!
        </Alert>
      )}
      {!(currentAlert.message === "" && currentAlert.variant === "") && (
          <Alert key="danger" variant={currentAlert.variant === "success" ? "success" : "danger"} onClose={() => {setCurrentAlert({message: "", variant: ""})}} dismissible>
            {currentAlert.message}
          </Alert>
        )}
      <Form noValidate validated={validatedSave} onSubmit={handleSubmit}>
        <Row className='align-items-center'>
          <Col>
            <FloatingLabel 
              controlId="floatingInput"
              label="Title"
              >
              <Form.Control required ref={titleRef} defaultValue={props.state[0].title ? props.state[0].title : undefined} type="text" placeholder='example' />
              <Form.Control.Feedback type='invalid'>Please provide title!</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col md="auto" className='mt-2 mt-md-0 mt-lg-0'>
            <div className='text-center'>
              <Button disabled={!!!currentUserState} type='submit' variant="primary" size='sm'>
                Save
              </Button>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false} >
                <Modal.Header closeButton>
                  <Modal.Title>Save Layout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Confirm save
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type='submit' variant="primary" onClick={() => {saveLayout(); handleClose(); }}>Save</Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className='text-center mt-1'>
              <DropdownButton disabled={!!!currentUserState} size="sm" id="dropdown-basic-button" title="Load">
                {layouts.map((val) => ((
                  <Dropdown.Item onClick={() => {loadLayout(val.id ? val.id : "")}}>{val.layout.title}</Dropdown.Item>
                )))}
              </DropdownButton>
            </div>
          </Col>
        </Row>  
      </Form>
    </>
  );
}

export default LayoutControls;