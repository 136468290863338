import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { Alert, Button, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../user/UserContext";

function Login() {

  const userContextState = useContext(UserContext);

  const navigate = useNavigate();

  const [curEmail, setCurEmail] = useState("");
  const [curPassword, setCurPassword] = useState("");

  const [currentAlert, setCurrentAlert] = useState("");

  async function login() {
    await signInWithEmailAndPassword(getAuth(), curEmail, curPassword)
      .then((userCredential) => {
        if (userContextState && userCredential.user.email) {
          navigate("/wheel-game");
        }
      }).catch((error) => {
        setCurrentAlert(error.message);
      });
  }

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    login();
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Container fluid>
      <Form onSubmit={onSubmit}>
        <Row className="mb-3">
          <Form.Group className="col-auto">
            <Form.Label>Login</Form.Label>
            <Form.Control required onChange={(event) => {setCurEmail(event.target.value)}} type="email" placeholder="user@email.com"> 
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="col-auto">
            <Form.Label>Password</Form.Label>
            <Form.Control required onChange={(event) => {setCurPassword(event.target.value)}} type="password" placeholder="password"> 
            </Form.Control>
          </Form.Group>      
        </Row>
        {!(currentAlert === "") && (
          <Alert key="danger" variant="danger" onClose={() => {setCurrentAlert("")}} dismissible>
            {currentAlert}
          </Alert>
        )}
        <Button className="col-auto" type='submit'>Login</Button>
      </Form>
    </Container>
  );
}

export default Login;