import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { Button, Container, Nav, Navbar, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../user/UserContext";

function AppNavBar() {
  const state = useContext(UserContext);
  const cState = state?.userState;
  const nav = useNavigate();

  const logout = async () => {
    await getAuth().signOut().then(() => {
      nav("/");
    }).catch((err) => {});
  }

  return (
    <>
    <Navbar>
      <Container fluid>
        <Nav>
          <Nav.Item>
            <Link to='/' className="nav-link align-middle">Home</Link>
          </Nav.Item>
          {!(cState) && (
            <>
              <Nav.Item>
                <Link to="/login" className="nav-link">Login</Link>
              </Nav.Item>
          
              <Nav.Item>
                <Link to="/register" className="nav-link">Register</Link>
              </Nav.Item>
            </>
          )}
        </Nav>
        
        <Stack direction="horizontal" gap={3}> 
        { cState && (
            <Navbar.Text className="justify-content-end">
              Signed in as: {state ? cState.email : ''}
            </Navbar.Text>
          ) 
        }

        { cState && (
              <>
                <Button onClick={logout} size="sm" variant="outline-danger">
                  Logout
                </Button>
              </>
            )
          }
        </Stack>
      </Container>
    </Navbar>
    <hr className="mt-0"/>
    </>
  );  
}

export default AppNavBar;