import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const img = require('../assets/wheel-game-img-2.png');

function Home() {
  const nav = useNavigate();

  return (
    <Container fluid>
      <Container fluid>
        <Row className="align-items-center">
          <div className="bg-dark bg-opacity-10 align-items-center" style={{borderRadius: 20}}>
            <p className="lead text-center mb-0 fs-4">Tools</p>
          </div>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card style={{ width: '12rem' }} bg='info'>
              <Card.Img variant="top" src={img} />
              <Card.Body>
                <Card.Title>Carnival Wheel</Card.Title>
                <Card.Text>
                  Set the center text and sectors of wheel for endless uses. 
                </Card.Text>
                <Button onClick={() => {
                  nav("/wheel-game")
                }} variant="light">Play Carnival Wheel</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
      <p className="lead fs-6 text-end fixed-bottom pr-2"><em>Educational website for students, designed by teachers. </em></p>
    </Container>
  );
}

export default Home;  