import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import AppNavBar from "../home/AppNavBar";
import { useState } from "react";
import { UserContext, ContextType } from "../user/UserContext";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";


function Layout() {
  const [userState, setUserState] = useState<ContextType>(null);
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserState(user);
    }
  }, [auth.currentUser])

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserState(user);
    } else {
      setUserState(null);
    }
  });

  return (
    <>
      <Container fluid>
        <UserContext.Provider value={{userState, setUserState}}>
          <AppNavBar/>
          <Outlet />
        </UserContext.Provider>
      </Container>
    </>
  );
}

export default Layout;