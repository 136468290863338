import {useState} from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { LayoutStateProp } from './Interfaces';
import SpinWheel from './SpinWheel';

function WheelModal(props : LayoutStateProp) {
  const [show, setShow] = useState(false);

  const [restart, restartWheelSpin] = useState(false);

  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true); 

  const restartWheel = () => { restartWheelSpin(!restart); }

  return (
    <>
      <Button className='col-md-3' variant="success" onClick={handleShow}>
          Spin
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Body style={{backgroundColor: 'lightblue'}}> 
          <SpinWheel wheelProp={{wheelLayout : props.state[0]}}></SpinWheel>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: 'lightpink'}} >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='spin' onClick={restartWheel}>
            Spin
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WheelModal;