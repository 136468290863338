import { useRef, useState } from "react";
import { Button, FloatingLabel, Form, Stack, Table } from "react-bootstrap";
import {WheelLayout, LayoutStateProp} from "./util/Interfaces";
import {CiCircleRemove} from 'react-icons/ci';
import {AiOutlineCheckCircle} from 'react-icons/ai';

function EntryControls(props : LayoutStateProp) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [showEntries, setShowEntries] = useState(true);
  const centerRef = useRef<null | any>(null);

  const placeholderValues : string[] = [];
  props.state && props.state[0].entries.map((val) => {
    placeholderValues.push(val);
    return 0;
  });

  if (centerRef.current) {
    centerRef.current.defaultValue = props.state[0].centerText;
  }
  
  const clearInputValues = () => {
    for (let input of inputRefs.current) {
      input.value = "";
    }
  }
  clearInputValues();

  const updateLayoutState = (layout : WheelLayout) => {
    props.state[1](layout);
  }

  const setEntry = (index : number) => {
    if (!placeholderValues[index]) return;
    const newEntries = [...props.state[0].entries];
    newEntries[index] = placeholderValues[index];
    updateLayoutState({...props.state[0], entries: newEntries});
  }

  const deleteEntry = (index : number) => {
    const newEntries = [...props.state[0].entries];
    newEntries.splice(index, 1);
    updateLayoutState({...props.state[0], entries: newEntries});
  }

  const addEntry = () => {
    const newEntries = [...props.state[0].entries]; 
    newEntries.push("");
    updateLayoutState({...props.state[0], entries: newEntries});
  }

  const setCenter = () => {
    if (!centerRef.current) return;
    updateLayoutState({...props.state[0], centerText: centerRef.current.value});
  }

  return (
    <Stack gap={2}>
        <FloatingLabel
        controlId="floatingInput"
        label="Center"
        className="mb-3"
        >
        <Form.Control ref={centerRef}
          defaultValue={props.state[0].centerText} 
          type="text" placeholder='example' />
        </FloatingLabel>

        <Button className="col-md-3 mx-auto" variant="primary" onClick={setCenter}>Set Center</Button>

        <Stack direction="horizontal" className="mx-auto" gap={2}>
          <div>
            <Button variant="primary" onClick={addEntry}>Add Entry</Button>
          </div>
          <div>
            <Button variant="primary" onClick={() => {setShowEntries(!showEntries)}}>{showEntries ? "Hide Entries" : "Show Entries"}</Button>
          </div>
        </Stack>

      <Stack gap={1} className='mx-auto'>
        {showEntries && (
          <Table striped bordered hover>
            <tbody>
              {
                props.state[0].entries.map((val, index) => ((
                  <tr>
                    <td><Button size="sm" variant="success" style={{borderRadius: 100}} onClick={() => {setEntry(index)}}><AiOutlineCheckCircle size={25}/></Button></td>
                    <td><input ref={(ref) => {
                if (ref && !inputRefs.current.includes(ref)) inputRefs.current?.push(ref);
              }} type="text" placeholder={val} onChange={(e) => {placeholderValues[index] = e.target.value}}></input></td>
                    <td><Button size="sm" variant="danger" style={{borderRadius: 100}} onClick={() => {deleteEntry(index)}}><CiCircleRemove size={25}/></Button></td>
                  </tr>
                )))
              }
            </tbody>
          </Table>
        )}
      </Stack>
    </Stack>
  );
}

export default EntryControls;