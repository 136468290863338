import { useContext, useState } from "react";
import { Alert, Button, Container, Form, Row } from "react-bootstrap";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase-config';
import { UserContext } from "../user/UserContext";
import { useNavigate } from "react-router-dom";

function Register() {
  const userContextState = useContext(UserContext);

  const navigate = useNavigate();

  const [curEmail, setCurEmail] = useState("");
  const [curPassword, setCurPassword] = useState("");

  const [currentAlert, setCurrentAlert] = useState("");

  async function register() {
    await createUserWithEmailAndPassword(auth, curEmail, curPassword)
      .then((userCredential) => {
        if (userContextState && userCredential.user.email) {
          navigate("/wheel-game");
        }
      }).catch((error) => {
        setCurrentAlert(error.message);
      });
  }

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    register();
    event.preventDefault();
    event.stopPropagation();
  }
  
  return (
    <Container fluid>
      <Form onSubmit={onSubmit}>
        <Row className="mb-3">
          <Form.Group className="col-auto">
            <Form.Label>Login</Form.Label>
            <Form.Control required onChange={(event) => {setCurEmail(event.target.value)}} type="email" placeholder="user@email.com"> 
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="col-auto">
            <Form.Label>Password</Form.Label>
            <Form.Control required onChange={(event) => {setCurPassword(event.target.value)}} type="password" placeholder="password"> 
            </Form.Control>
          </Form.Group>      
        </Row>
        {!(currentAlert === "") && (
          <Alert key="danger" variant="danger" onClose={() => {setCurrentAlert("")}} dismissible>
            {currentAlert}
          </Alert>
        )}
        <Button className="col-auto" type='submit'>Register</Button>
      </Form>
    </Container>
  );
}

export default Register;