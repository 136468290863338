import { Wheel } from "./Wheel";
import Canvas, { resizeCanvasToParent } from "./Canvas";
import { WheelProp } from "./Interfaces";

function SpinWheel(props : {wheelProp : WheelProp}) {
  const wheel = new Wheel(props.wheelProp.wheelLayout);

  wheel.setRandTurns(400, 150);

  const draw = (ctx : CanvasRenderingContext2D, frameCount : number) => {
    resizeCanvasToParent(ctx.canvas);
    
    wheel.setPosition(ctx.canvas.width / 2, ctx.canvas.height / 2);
    wheel.setOuterRadius(Math.min((ctx.canvas.width - 100) / 2, (ctx.canvas.height - 100) / 2));

    if (wheel.getCurrentTurn() < wheel.getMaxTurns()) {
      wheel.spinWheel(ctx);
    }
    wheel.draw(ctx);
  }

  return (
    <>
      <Canvas draw={draw} preDraw={(ctx: CanvasRenderingContext2D) => {}} postDraw={(ctx: CanvasRenderingContext2D) => {}}></Canvas>
    </>
  );
}

export default SpinWheel;