import { useState } from "react";
import LayoutControls from "./LayoutControls";
import EntryControls from "./EntryControls";
import { WheelLayout } from "./util/Interfaces";
import { Container, Stack } from "react-bootstrap";
import WheelModal from "./util/WheelModal";

function WheelPage() {
  const layoutState = useState<WheelLayout>(
    {
    centerText: "", 
    entries: ["", ""],
    title: null
    }
  );

  return (
    <Container fluid className="mt-4">
      <Stack gap={2}>
        <LayoutControls state={layoutState} />
        
        <EntryControls state={layoutState} />

        <WheelModal state={layoutState}/>
      </Stack>
    </Container>
  );
}

export default WheelPage;