import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../home/Home";
import Layout from "./Layout";
import WheelIndex from "../wheel-game/WheelIndex";
import Register from "../home/Register";
import Login from "../home/Login";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="wheel-game" element={<WheelIndex/>} />
          <Route path="register" element={<Register/>} />
          <Route path="login" element={<Login/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes;