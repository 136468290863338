import { useEffect, useRef } from 'react';
import { CanvasProps } from './Interfaces';

export function resizeCanvas(canvas : HTMLCanvasElement) {
  const {width, height} = canvas.getBoundingClientRect();
  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio:ratio=1 } = window;
    const context = canvas.getContext('2d');
    canvas.width = width*ratio;
    canvas.height = height*ratio;
    context?.scale(ratio, ratio);
    return true
  }
  return false;
}

export function resizeCanvasToParent(canvas : HTMLCanvasElement) {
  const {width, height} = canvas.parentElement?.getBoundingClientRect() ? canvas.parentElement?.getBoundingClientRect() : {width: 300, height: 150};
  if ((Math.abs(canvas.width - width) > 2) || (Math.abs(canvas.height - height) > 2)) {

    const ratio = 1;

    canvas.width = width*ratio;
    canvas.height = height*ratio;

    return true
  }
  return false;
}

const Canvas = (props : CanvasProps) => {
  const canvasRef = useCanvas(props);
  
  return <canvas ref={canvasRef}/>
}

const useCanvas = (props : CanvasProps) => {
  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const {draw, ...r} = props;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

// if (canvas) {
//   let dpr = window.devicePixelRatio;
//   let rect = canvas?.getBoundingClientRect();

//   console.log(dpr);
//   console.log(rect);

//   canvas.width = rect?.width * dpr;
//   canvas.width = rect?.height * dpr;

//   context?.scale(dpr, dpr);

//   canvas.style.width = rect.width + 'px';
//   canvas.style.height = rect.height + 'px';

// }

    let frameCount = 0;
    let animationFrameid : number;

    const render = () => {
      frameCount++;
      if (context)
        draw(context, frameCount);
      animationFrameid = window.requestAnimationFrame(render);
      
    }
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameid);
    }

  }, [draw]);

  return canvasRef;
}

export default Canvas